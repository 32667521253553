import { eventGoodsSetter } from 'utils/goods';
import { returnAuthConfig } from 'utils/auth';
import { ActionCreator as BasketActionCreator } from '../basket/basket';

const initialState = {
  events: [
    {
      external_url: '',
      cities: [{ city: { name_ru: '' }, start_time: '' }],
      event_city: [{ city: { name_ru: '' }, start_time: '' }],
      title: '',
      description: '',
      public_id: '1e2',
      slider: 'big',
      banners: {
        home_page: {
          desktop_picture: '',
          mobile_picture: '',
          text_color: '',
          slider_logo: '',
          button_color: '',
        },
      },
      event_type: {
        logo: '',
        title: '',
        video_promo: '',
        project: {
          link: '',
          picture_main: '',
        },
      },
    },
  ],
  filteredEvents: [],
  filteredEventsInsurance: [],
  formats: [],
  teams: [],
  cityId: 524901, // default value
  news: [],
  eventType: [],
  eventCities: [],
  sizes: [],
  sizesById: [],
  eventGoods: [],
  event: {},
  address: '',
  delivery: {
    regions: [],
    cities: [],
    price: { cost: 0 },
  },
  good: { proportions: [] },
  medal: null,
  resultCities: [],
  filteredResults: [],
  errorOneNews: '',
  oneNews: null,
  clubs: [],
  club: null,
  userClubs: [],
  isJoinClub: '',
  isLeaveClub: '',
  competitionTypes: [],
  submitClubResultStatus: {},
  clubCompetitions: [],
  eventGoodsById: [],
  activeInsurances: [],
  pastInsurances: [],
};

export const ActionType = {
  LOAD_EVENTS: `LOAD_EVENTS`,
  LOAD_FORMAT: `LOAD_FORMAT`,
  LOAD_TEAMS: `LOAD_TEAMS`,
  SET_CITY_ID: `SET_CITY_ID`,
  LOAD_NEWS: `LOAD_NEWS`,
  SET_TYPE: `SET_TYPE`,
  SET_CITIES: `SET_CITIES`,
  SET_FILTERED_EVENTS: `SET_FILTERED_EVENTS`,
  SET_FILTERED_EVENTS_INSURANCE: `SET_FILTERED_EVENTS_INSURANCE`,
  SET_SIZES: `SET_SIZES`,
  SET_SIZES_BY_ID: `SET_SIZES_BY_ID`,
  SET_EVENT_GOODS: `SET_EVENT_GOODS`,
  SET_EVENT_GOODS_BY_ID: `SET_EVENT_GOODS_BY_ID`,
  CLEAN_EVENT_GOODS: `CLEAN_EVENT_GOODS`,
  LOAD_EVENT: `LOAD_EVENT`,
  CLEAN_FORMATS: `CLEAN_FORMATS`,
  LOAD_DELIVERY_REGIONS: `LOAD_DELIVERY_REGIONS`,
  LOAD_DELIVERY_CITIES: `LOAD_DELIVERY_CITIES`,
  SET_DELIVERY_PRICE: `SET_DELIVERY_PRICE`,
  SET_ADDRESS: 'SET_ADDRESS',
  SET_GOODS: 'SET_GOODS',
  SET_RESULTS: 'SET_RESULTS',
  SET_FILTERED_RESULTS: 'SET_FILTERED_RESULTS',
  SET_ERROR_ONE_NEWS: 'SET_ERROR_ONE_NEWS',
  LOAD_ONE_NEWS: 'LOAD_ONE_NEWS',
  LOAD_CLUBS: 'LOAD_CLUBS',
  LOAD_CLUB: 'LOAD_CLUB',
  LOAD_USER_CLUBS: 'LOAD_USER_CLUBS',
  JOIN_CLUB: 'JOIN_CLUB',
  LEAVE_CLUB: 'LEAVE_CLUB',
  LEAVE_USER_CLUB: 'LEAVE_USER_CLUB',
  JOIN_USER_CLUB: 'JOIN_USER_CLUB',
  LOAD_COMPETITION_TYPES: 'LOAD_COMPETITION_TYPES',
  SUBMIT_CLUB_RESULT_STATUS: 'SUBMIT_CLUB_RESULT_STATUS',
  LOAD_CLUB_COMPETITIONS: 'LOAD_CLUB_COMPETITIONS',
  CLEAR_CLUB_COMPETITIONS: 'CLEAR_CLUB_COMPETITIONS',
  CLEAR_SIZE: 'CLEAR_SIZE',
  SET_MEDAL: 'SET_MEDAL',
  SET_ACTIVE_USER_INSURANCES: 'SET_ACTIVE_USER_INSURANCES',
  SET_PAST_USER_INSURANCES: 'SET_PAST_USER_INSURANCES',
};

export const ActionCreator = {
  loadEvents: (events) => ({
    type: ActionType.LOAD_EVENTS,
    payload: events,
  }),
  setMedal: (medal) => ({
    type: ActionType.SET_MEDAL,
    payload: medal,
  }),
  setActiveUserIncurances: (insurances) => ({
    type: ActionType.SET_ACTIVE_USER_INSURANCES,
    payload: insurances,
  }),
  setPastUserIncurances: (insurances) => ({
    type: ActionType.SET_PAST_USER_INSURANCES,
    payload: insurances,
  }),
  clearSize: (size) => ({
    type: ActionType.CLEAR_SIZE,
    payload: size,
  }),
  setFilteredResults: (results) => ({
    type: ActionType.SET_FILTERED_RESULTS,
    payload: results,
  }),

  setClubs: (clubs) => ({
    type: ActionType.LOAD_CLUBS,
    payload: clubs,
  }),
  setClub: (club) => ({
    type: ActionType.LOAD_CLUB,
    payload: club,
  }),

  leaveClub: (isLeaveClub) => ({
    type: ActionType.LEAVE_CLUB,
    payload: isLeaveClub,
  }),
  leaveUserClub: (id) => ({
    type: ActionType.LEAVE_USER_CLUB,
    payload: id,
  }),

  joinUserClub: (id) => ({
    type: ActionType.JOIN_USER_CLUB,
    payload: id,
  }),

  joinClub: (isLeaveClub) => ({
    type: ActionType.JOIN_CLUB,
    payload: isLeaveClub,
  }),

  setUserClubs: (clubs) => ({
    type: ActionType.LOAD_USER_CLUBS,
    payload: clubs,
  }),

  loadFormats: (format) => ({
    type: ActionType.LOAD_FORMAT,
    payload: format,
  }),
  loadFormatsByFormatId: (format) => ({
    type: ActionType.LOAD_FORMAT,
    payload: format,
  }),

  loadTeams: (teams) => ({
    type: ActionType.LOAD_TEAMS,
    payload: teams,
  }),

  setCityId: (id) => ({
    type: ActionType.SET_CITY_ID,
    payload: id,
  }),

  loadNews: (news) => ({
    type: ActionType.LOAD_NEWS,
    payload: news,
  }),

  loadOneNews: (news) => ({
    type: ActionType.LOAD_ONE_NEWS,
    payload: news,
  }),

  setType: (type) => ({
    type: ActionType.SET_TYPE,
    payload: type,
  }),

  setEventCities: (cities) => ({
    type: ActionType.SET_CITIES,
    payload: cities,
  }),

  setFilteredEvents: (events) => ({
    type: ActionType.SET_FILTERED_EVENTS,
    payload: events,
  }),
  setFilteredEventsInsurance: (events) => ({
    type: ActionType.SET_FILTERED_EVENTS_INSURANCE,
    payload: events,
  }),

  setSizes: (size) => ({
    type: ActionType.SET_SIZES,
    payload: size,
  }),
  setSizesById: (size) => ({
    type: ActionType.SET_SIZES_BY_ID,
    payload: size,
  }),
  setResultCities: (result) => ({
    type: ActionType.SET_RESULTS,
    payload: result,
  }),

  setEventGoods: (goods) => ({
    type: ActionType.SET_EVENT_GOODS,
    payload: goods,
  }),
  setEventGoodsById: (goods) => ({
    type: ActionType.SET_EVENT_GOODS_BY_ID,
    payload: goods,
  }),
  setGood: (good) => ({
    type: ActionType.SET_GOODS,
    payload: good,
  }),

  // # КОСТЫЛЬ for cleaning state (eventGoods)
  cleanEventGoods: (arr) => ({
    type: ActionType.CLEAN_EVENT_GOODS,
    payload: arr,
  }),

  setLoadedEvent: (event) => ({
    type: ActionType.LOAD_EVENT,
    payload: event,
  }),

  // # КОСТЫЛЬ for cleaning state (event, formats)
  cleanFormats: (arr) => ({
    type: ActionType.CLEAN_FORMATS,
    payload: arr,
  }),

  loadDeliveryRegions: (regions) => ({
    type: ActionType.LOAD_DELIVERY_REGIONS,
    payload: regions,
  }),

  loadDeliveryCities: (cities) => ({
    type: ActionType.LOAD_DELIVERY_CITIES,
    payload: cities,
  }),

  setDeliveryPrice: (delivery_price) => ({
    type: ActionType.SET_DELIVERY_PRICE,
    payload: delivery_price,
  }),
  setAddress: (address) => ({
    type: ActionType.SET_ADDRESS,
    payload: address,
  }),
  setErrorOneNews: (message) => ({
    type: ActionType.SET_ERROR_ONE_NEWS,
    payload: message,
  }),
  loadCompetitionTypes: (types) => ({
    type: ActionType.LOAD_COMPETITION_TYPES,
    payload: types,
  }),
  submitClubResultStatus: (type) => ({
    type: ActionType.SUBMIT_CLUB_RESULT_STATUS,
    payload: type,
  }),
  loadClubCompetitions: (list) => ({
    type: ActionType.LOAD_CLUB_COMPETITIONS,
    payload: list,
  }),
  clearClubCompetitions: (list) => ({
    type: ActionType.CLEAR_CLUB_COMPETITIONS,
    payload: list,
  }),
};

export const Operation = {
  loadEvents: () => async (dispatch, _, api) => {
    const response = await api.get(`/api/event/list`);

    if (response.status === 200 && response.data.values.length) {
      dispatch(ActionCreator.loadEvents(response.data.values)); // - list activities
    } /* else if (response.status >= 400) backendLogger(response); */
  },

  joinTheClub: (public_id) => async (dispatch, _, api) => {
    const config = returnAuthConfig();
    const response = await api.put(
      `/api/user/club/new/${public_id}`,
      null,
      config
    );

    if (response.status === 200) {
      dispatch(ActionCreator.joinClub(response));
      dispatch(ActionCreator.joinUserClub({ public_id }));
    } /* else if (response.status >= 400) backendLogger(response); */
  },

  leaveTheClub: (public_id) => async (dispatch, _, api) => {
    const config = returnAuthConfig();
    const response = await api.put(
      `/api/user/club/pull/${public_id}`,
      null,
      config
    );

    if (response.status === 200) {
      dispatch(ActionCreator.leaveClub(response));
      dispatch(ActionCreator.leaveUserClub(public_id));
    } /* else if (response.status >= 400) backendLogger(response); */
  },

  loadEventsVolounteer: () => async (dispatch, _, api) => {
    const config = returnAuthConfig();
    const response = await api.get(`/api/event/list`, config);

    if (response.status === 200 && response.data.values.length) {
      dispatch(ActionCreator.loadEvents(response.data.values)); // - list activities
    } /* else if (response.status >= 400) backendLogger(response); */
  },

  loadFilteredEvents:
    (data, setIsEvents = () => {}) =>
    async (dispatch, _, api) => {
      const response = await api.post(`/api/event_city/filtered`, data);

      if (response.status === 200) {
        dispatch(ActionCreator.setFilteredEvents(response.data.values));
        if (!response.data.values.length) {
          setIsEvents(true);
        } else {
          setIsEvents(false);
        }
      }
    },
  loadFilteredEventsInsurance:
    (data, setIsEvents = () => {}) =>
    async (dispatch, _, api) => {
      const response = await api.post(`/api/event_city/filtered`, data);

      if (response.status === 200) {
        dispatch(
          ActionCreator.setFilteredEventsInsurance(response.data.values)
        );
        if (!response.data.values.length) {
          setIsEvents(true);
        } else {
          setIsEvents(false);
        }
      }
    },
  loadFilteredResults: (data) => async (dispatch, _, api) => {
    const response = await api.post(`/api/results/event_city/filtered`, data);

    if (response.status === 200) {
      dispatch(ActionCreator.setFilteredResults(response.data.values));
    }
  },

  loadFormats:
    (
      event_city_id,
      hidden = false,
      dispatchName = `loadFormats`,
      setIsLoadFormats = null
    ) =>
    async (dispatch, _, api) => {
      const response = await api.get(
        `/api/event/event_format/${event_city_id}${
          hidden ? '?hidden=true' : ''
        }`
      );

      if (response.status === 200) {
        dispatch(ActionCreator[dispatchName](response.data.values));
        if (setIsLoadFormats) {
          setIsLoadFormats(true);
        }
      } /* else if (response.status >= 400) {
        backendLogger(response);
      } */
    },
  loadActiveUserInsurances: () => async (dispatch, _, api) => {
    const config = returnAuthConfig();
    const response = await api.get('/api/user/insurances/valid', config);

    if (response.status === 200) {
      dispatch(ActionCreator.setActiveUserIncurances(response.data.values));
    }
  },
  loadPastUserInsurances: () => async (dispatch, _, api) => {
    const config = returnAuthConfig();
    const response = await api.get('/api/user/insurances/past', config);

    if (response.status === 200) {
      dispatch(ActionCreator.setPastUserIncurances(response.data.values));
    }
  },
  loadFormatsByFormatId: (format_id) => async (dispatch, _, api) => {
    const response = await api.get(`/api/event_format/${format_id}`);
    if (response.status === 200) {
      dispatch(BasketActionCreator.setTicketFormats(response.data));
      dispatch(ActionCreator.loadFormats(response.data));
    } /* else if (response.status >= 400) {
      backendLogger(response);
    } */
  },
  loadClubs: () => async (dispatch, _, api) => {
    const response = await api.get(`/api/clubs`);
    if (response.status === 200) {
      dispatch(ActionCreator.setClubs(response.data));
    } /* else if (response.status >= 400) {
      backendLogger(response);
    } */
  },
  loadClubsByPublic_id: (public_id) => async (dispatch, _, api) => {
    const response = await api.get(`/api/clubs/${public_id}`);
    if (response.status === 200) {
      dispatch(ActionCreator.setClub(response.data));
    } /* else if (response.status >= 400) {
      backendLogger(response);
    } */
  },

  loadUserClubs: () => async (dispatch, _, api) => {
    const config = returnAuthConfig();
    const response = await api.get(`/api/user/clubs`, config);
    if (response.status === 200) {
      dispatch(ActionCreator.setUserClubs(response.data.values));
    } /* else if (response.status >= 400) {
      backendLogger(response);
    } */
  },

  loadNews: () => async (dispatch, _, api) => {
    const response = await api.get(`/api/news`);
    if (response.status === 200) {
      dispatch(ActionCreator.loadNews(response.data));
    } /* else if (response.status >= 400) {
      backendLogger(response);
    } */
  },

  loadOneNews: (public_id) => async (dispatch, _, api) => {
    const response = await api.get(`/api/news/one/${public_id}`);
    if (response.status === 200) {
      dispatch(ActionCreator.loadOneNews(response.data));
    } /* else if (response.status >= 400) {
      dispatch(ActionCreator.setErrorOneNews(response.data.message));
      backendLogger(response);
    } */
  },

  loadNewsPreview: (limit) => async (dispatch, _, api) => {
    const response = limit
      ? await api.get(`/api/news/preview?limit=${limit}`)
      : await api.get(`/api/news/preview`);
    if (response.status === 200) {
      dispatch(ActionCreator.loadNews(response.data));
    } /* else if (response.status >= 400) {
      backendLogger(response);
    } */
  },

  loadResultCities: () => async (dispatch, _, api) => {
    const response = await api.get('/api/results/cities');

    if (response.status === 200) {
      dispatch(ActionCreator.setResultCities(response.data.values));
    } /* else if (response.status >= 400) {
      backendLogger(response);
    } */
  },

  loadEventType: () => async (dispatch, _, api) => {
    const response = await api.get(`api/event_type/list`);

    if (response.status === 200)
      dispatch(ActionCreator.setType(response.data.values));
  },

  loadEventCities: () => async (dispatch, _, api) => {
    const response = await api.get(`api/event/cities`);

    if (response.status === 200)
      dispatch(ActionCreator.setEventCities(response.data.values));
  },

  loadSize: (data) => async (dispatch, _, api) => {
    const response = await api.get(
      `/api/shop/shirts/${data.event_city_public_id}`
    );

    if (response.status === 200)
      if (response.data.values.length > 0) {
        dispatch(ActionCreator.setSizes(response.data.values));
      }
  },

  loadSizeById: (data, setIsLoading) => async (dispatch, _, api) => {
    const response = await api.get(
      `/api/shop/shirts/${data.event_city_public_id}`
    );
    try {
      if (response.status === 200) {
        if (response.data.values.length > 0) {
          dispatch(
            ActionCreator.setSizesById({
              sizes: response.data.values,
              id: `${data.event_public_id}${data.event_city_public_id}`,
            })
          );
          setIsLoading(false);
        } else if (!response.data.values.length) {
          setIsLoading(false);
        }
      }
    } catch (e) {
      setIsLoading(false);
    }
  },

  loadEventGoods: (id) => async (dispatch, _, api) => {
    const response = await api.get(`/api/shop/related/${id}`);

    if (response.status === 200)
      dispatch(ActionCreator.setEventGoods({ data: response.data.values, id }));
    dispatch(
      ActionCreator.setEventGoodsById({ data: response.data.values, id })
    );
  },

  loadGood: () => async (dispatch, _, api) => {
    const response = await api.get(
      '/api/shop/product/18f88686a3ae3f4eebf08cfae807a6c58635ac1f'
    );

    if (response.status === 200)
      dispatch(ActionCreator.setGood(response.data && response.data));
    /* else if (response.status >= 400) {
      backendLogger(response);
    } */
  },

  loadMedal: () => async (dispatch, _, api) => {
    const response = await api.get(
      '/api/shop/product/190ba3238ce08da60220668278b2f92c68e967e7'
    );

    if (response.status === 200)
      dispatch(ActionCreator.setMedal(response.data && response.data));
    /* else if (response.status >= 400) {
      backendLogger(response);
    } */
  },

  loadEvent: (public_id) => async (dispatch, _, api) => {
    const response = await api.get(`/api/event/id/${public_id}`);

    if (response.status === 200)
      dispatch(ActionCreator.setLoadedEvent(response.data));
  },

  loadTeams: (format_public_id) => async (dispatch, _, api) => {
    const response = await api.get(`/api/teams/${format_public_id}`);

    if (response.status === 200)
      dispatch(ActionCreator.loadTeams(response.data.values));
    else dispatch(ActionCreator.loadTeams([]));
  },

  // Загрузка регионов доставки
  loadDeliveryRegions: () => async (dispatch, _, api) => {
    const response = await api.get(`/api/location/region/list/RU`);

    if (response?.status === 200) {
      dispatch(ActionCreator.loadDeliveryRegions(response.data.values));
    }
  },

  // Загрузка регионов доставки
  loadDeliveryCities: (city_id) => async (dispatch, _, api) => {
    const response = await api.get(`/api/location/city/region/${city_id}`);
    if (response.status === 200) {
      dispatch(ActionCreator.loadDeliveryCities(response.data.values));
    }
  },

  // Загрузка стоимости доставки
  setDeliveryPrice: (deliveryObject) => async (dispatch, _, api) => {
    const response = await api.post(`/api/shop/delivery/price`, deliveryObject);
    if (response.status === 200) {
      dispatch(ActionCreator.setDeliveryPrice(response.data.delivery));
    }
  },

  getAddressByIndex: (index) => async (dispatch, _, api) => {
    const response = await api.get(`/api/pochta/${index}`);
    if (response.status === 200) {
      dispatch(ActionCreator.setAddress(response.data));
    }
  },
  loadCompetitionTypes: () => async (dispatch, _, api) => {
    const response = await api.get('/api/list/competition/type');
    if (response.status === 200) {
      dispatch(ActionCreator.loadCompetitionTypes(response.data.values));
    }
  },
  submitResultClub:
    (data, clubId, setIsLoading) => async (dispatch, _, api) => {
      const config = returnAuthConfig();
      const response = await api.post('/api/user/competition', data, config);
      if (response.status === 200) {
        dispatch(
          ActionCreator.submitClubResultStatus({
            type: 'success',
            message: 'Ваши результаты успешно загружены',
          })
        );

        await api.put(
          `/api/user/club/competition/${response.data.public_id}/${clubId}`,
          null,
          config
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
        dispatch(
          ActionCreator.submitClubResultStatus({
            type: 'error',
            message: 'Не удалось загрузить ваши результаты',
          })
        );
      }
    },
  loadClubCompetitions: (id) => async (dispatch, _, api) => {
    const response = await api.get(`/api/club/competition/list/${id}`);
    if (response.status === 200) {
      dispatch(ActionCreator.loadClubCompetitions(response.data.values));
    }
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.LOAD_EVENTS:
      return { ...state, events: action.payload };
    case ActionType.SET_FILTERED_RESULTS:
      return { ...state, filteredResults: action.payload };
    case ActionType.SET_FILTERED_EVENTS:
      return { ...state, filteredEvents: action.payload };
    case ActionType.SET_FILTERED_EVENTS_INSURANCE:
      return { ...state, filteredEventsInsurance: action.payload };
    case ActionType.LOAD_FORMAT:
      return { ...state, formats: action.payload };
    case ActionType.LOAD_CLUBS:
      return { ...state, clubs: action.payload };
    case ActionType.LOAD_CLUB:
      return { ...state, club: action.payload };
    case ActionType.LOAD_USER_CLUBS:
      return { ...state, userClubs: action.payload };

    case ActionType.JOIN_USER_CLUB:
      return { ...state, userClubs: [...state.userClubs, action.payload] };
    case ActionType.LEAVE_USER_CLUB:
      return {
        ...state,
        userClubs: state.userClubs.filter(
          (el) => el.public_id !== action.payload
        ),
      };

    case ActionType.LEAVE_CLUB:
      return { ...state, isLeaveClub: action.payload };
    case ActionType.JOIN_CLUB:
      return { ...state, isJoinClub: action.payload };
    case ActionType.LOAD_TEAMS:
      return { ...state, teams: action.payload };
    case ActionType.LOAD_NEWS:
      return { ...state, news: action.payload };
    case ActionType.LOAD_ONE_NEWS:
      return { ...state, oneNews: action.payload };
    case ActionType.SET_TYPE:
      return { ...state, eventType: action.payload };
    case ActionType.SET_CITY_ID:
      return { ...state, cityId: action.payload };
    case ActionType.SET_CITIES:
      return { ...state, eventCities: action.payload };
    case ActionType.SET_SIZES:
      return {
        ...state,
        sizes: [
          ...action.payload,
          /*   { size: 'Футболка не нужна', count: 'defaultValue' }, */
        ],
      };
    case ActionType.SET_SIZES_BY_ID:
      return {
        ...state,
        sizesById: [
          ...state.sizesById,
          {
            id: action.payload.id,
            sizes: [
              ...action.payload.sizes,
              /*  { size: 'Футболка не нужна', count: 'defaultValue' }, */
            ],
          },
        ],
      };
    case ActionType.CLEAR_SIZE:
      return { ...state, sizes: [], sizesById: [] };
    case ActionType.SET_ERROR_ONE_NEWS:
      return { ...state, errorOneNews: action.payload };
    case ActionType.SET_EVENT_GOODS:
      return {
        ...state,
        eventGoods: eventGoodsSetter(state.eventGoods, action.payload),
      };
    case ActionType.SET_EVENT_GOODS_BY_ID:
      return {
        ...state,
        eventGoodsById: [
          ...state.eventGoodsById,
          {
            id: action.payload.id,
            data: [...action.payload.data],
          },
        ],
      };
    case ActionType.SET_ACTIVE_USER_INSURANCES:
      return { ...state, activeInsurances: action.payload };
    case ActionType.SET_PAST_USER_INSURANCES:
      return { ...state, pastInsurances: action.payload };
    case ActionType.CLEAN_EVENT_GOODS:
      return { ...state, eventGoods: [] };
    case ActionType.SET_RESULTS:
      return { ...state, resultCities: action.payload };
    case ActionType.LOAD_EVENT:
      return { ...state, event: action.payload };
    case ActionType.CLEAN_FORMATS:
      return { ...state, event: {}, formats: action.payload };

    case ActionType.LOAD_DELIVERY_REGIONS:
      return {
        ...state,
        delivery: { ...state.delivery, regions: action.payload },
      };
    case ActionType.LOAD_DELIVERY_CITIES:
      return {
        ...state,
        delivery: { ...state.delivery, cities: action.payload },
      };
    case ActionType.SET_DELIVERY_PRICE:
      return {
        ...state,
        delivery: { ...state.delivery, price: action.payload },
      };
    case ActionType.SET_ADDRESS:
      return { ...state, address: action.payload };
    case ActionType.SET_GOODS:
      return { ...state, good: action.payload };
    case ActionType.SET_MEDAL:
      return { ...state, medal: action.payload };
    case ActionType.LOAD_COMPETITION_TYPES:
      return { ...state, competitionTypes: action.payload };
    case ActionType.SUBMIT_CLUB_RESULT_STATUS:
      return { ...state, submitClubResultStatus: action.payload };
    case ActionType.LOAD_CLUB_COMPETITIONS:
      return { ...state, clubCompetitions: action.payload };
    case ActionType.CLEAR_CLUB_COMPETITIONS:
      return { ...state, clubCompetitions: action.payload };
    default:
      return state;
  }
};
